import { createApp, readonly } from 'vue'
import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import axios from 'axios'
import { createPinia } from 'pinia'
// @ts-ignore
import locale from 'view-ui-plus/dist/locale/en-US'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import '@/assets/lulubuy-theme/index.less'
import config from './config/'
import { store } from './store'
import { router } from './router'
import filters from './filters'
// @ts-ignore
import shortid from 'shortid'
import Helpers from './plugins/Helpers.js'
// @ts-ignore
import Vue3Sanitize from 'vue-3-sanitize'
import 'flag-icons/css/flag-icons.min.css'

import SmoothReflow from '@/components/_shared/SmoothReflow.vue'
import RegisterStoreModule from '@/mixins/RegisterStoreModule'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTruck, faEuroSign, faCalendarDays, faPercentage, faUpload, faSearchPlus } from '@fortawesome/free-solid-svg-icons'

// @ts-ignore
import Lodash from 'lodash'

// import '../__tests__/api-mocks/InboundOverviewApiMock'
// import '../__tests__/api-mocks/InboundOverviewApiMock.ts'

library.add(faTruck, faEuroSign, faCalendarDays, faPercentage, faUpload, faSearchPlus)

const app = createApp(App)
app.use(config)
app.use(Helpers)
app.use(Vue3Sanitize)

app.component('font-awesome-icon', FontAwesomeIcon)

app.provide('$appConfig', readonly(app.config.globalProperties.$appConfig))
app.provide('$helpers', readonly(app.config.globalProperties.$Helpers))

app.config.globalProperties.$_ = Lodash
app.config.globalProperties.$shortid = shortid
app.config.globalProperties.$filters = filters

const pinia = createPinia();
pinia.use(({ store }) => {
  store.$conf = app.config.globalProperties.$appConfig
  store.$Helpers = app.config.globalProperties.$Helpers
  store.$sanitize = app.config.globalProperties.$sanitize
});
app.use(pinia)

const myStore = store() as any
myStore.$conf = app.config.globalProperties.$appConfig
myStore.$Helpers = app.config.globalProperties.$Helpers
myStore.$sanitize = app.config.globalProperties.$sanitize

app.config.globalProperties.$store = myStore

const myRouter = router(myStore)

// TODO move to own file
const token = localStorage.getItem('authToken')
if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

app.config.globalProperties.$http = axios

app.use(myStore)
    .use(myRouter)
    .use(require('vue-shortkey'), { prevent: ['input', 'textarea', 'select', '.ivu-select'] })
    .use(ViewUIPlus, { locale })
    .component('SmoothReflow', SmoothReflow)
    .mixin(RegisterStoreModule)

// Redirect to login if status is 401
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            myStore.dispatch('login/logout').then(() => {
                myRouter.push('/login')
            })
        }

        return Promise.reject(error)
    }
)

myRouter.isReady().then(() => app.mount('#app'))
