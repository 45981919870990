import axios from 'axios'

const state = {
    jobs: {
        pending: [],
        completed: [],
        failed: [],
    },
    jobs_full: {
        pending: {},
        completed: {},
        failed: {},
    },
    jobPromise: Promise.resolve(),
}

const getters = {
    allJobs(state) {
        return state.jobs
    },
}

const actions = {
    async get_job_batches(context, { jobStatus, pcursor, perPage }) {
        let queryString = '?'

        if (perPage) {
            queryString = queryString + '&perPage=' + perPage
        } else {
            queryString = queryString + '&perPage=100'
        }

        if (pcursor) {
            queryString = queryString + '&page=' + pcursor
        }

        if (jobStatus) {
            queryString = queryString + '&status=' + jobStatus
        }

        return await axios
            .get(this.$conf.apiUrl + 'job-batches' + queryString)
            .then((response) => {
                return response.data['job-batches']
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async get_all_jobs(context,{ searchBy, searchValue, pcursor }) {

        let queryString = '?'

        if (searchBy) {
            queryString = queryString + searchBy + '=' + searchValue
        }

        if (pcursor) {
            queryString = queryString + '&page=' + pcursor
        }

        return await axios
            .get(this.$conf.apiUrl + 'job-batches' + queryString)
            .then((response) => {
                return response.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async load_jobs({ dispatch }, { jobStatus, pcursor, perPage }) {
        return await dispatch('get_job_batches', { jobStatus, pcursor, perPage }).then(async (jobs) => {
            const promise = await dispatch('NORMALIZE_DATA', {
                jobs: jobs,
                refKey: 'id',
                jobStatus: jobStatus,
            })
            return promise
        })
    },

    NORMALIZE_DATA({ commit }, { jobs, refKey, jobStatus }) {
        const list = Object.freeze(jobs.map((o) => o[refKey]))
        const data = Object.freeze(this.$Helpers.array2Object(jobs, refKey))

        commit('ADD_DATA', { list, data, jobStatus })
    },
}

const mutations = {
    ADD_DATA(state, { list, data, jobStatus }) {
        for (const id of list) {
            if (!state['jobs'][jobStatus].includes(id)) {
                state['jobs'][jobStatus].push(id)
            }
            state['jobs_full'][jobStatus][id] = data[id]
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
