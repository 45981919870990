import { createStore } from 'vuex'

import login from '@/store/modules/login'
import suppliers from '@/store/modules/suppliers'
import ListSelectorSuppliers from '@/store/modules/ListSelector/ListSelectorSuppliers'
import shipping_weights from '@/store/modules/shipping_weights'
import users from '@/store/modules/users'
import source from '@/store/modules/source'
import alerts from '@/store/modules/alerts'
import products from '@/store/modules/products'
import product_details from '@/store/modules/product_details'
import handlingTimes from '@/store/modules/B2C/handlingTimes'
import margins from '@/store/modules/B2C/margins'
import jobs from '@/store/modules/Jobs/jobs'
import orders from '@/store/modules/B2C/orders'
import shippingCosts from '@/store/modules/B2C/shippingCosts'
import configuration from '@/store/modules/Settings/configuration'
import ExcludedSkus from "@/store/modules/B2C/ExcludedSkus"
import shipments from "@/store/modules/B2C/shipments"
import DiscountCampaign from '@/store/modules/B2C/discountCampaign'
import ShipmentLocations from '@/store/modules/B2C/shipmentLocations'
import CostCenter from "@/store/modules/Accounting/CostCenter";
import Accounts from "@/store/modules/Accounting/Accounts";
import countries from "@/store/modules/countries";

export const store = () =>
    createStore({
        namespaced: true,

        modules: {
            login,
            ListSelectorSuppliers,
            source,
            suppliers,
            shipping_weights,
            users,
            product_details,
            products,
            alerts,
            handlingTimes,
            margins,
            orders,
            shippingCosts,
            jobs,
            configuration,
            ExcludedSkus,
            shipments,
            DiscountCampaign,
            CostCenter,
            Accounts,
            countries,
            ShipmentLocations
        },

        state: {},

        getters: {},

        actions: {},

        mutations: {},
    })
