import axios from "axios";

const state = {
    centers: [],
    centers_full:{}
}

const getters = {

}

const actions = {
    async getCenters(context, { pcursor, label, perPage}){
        let queryString = '?'

        if (pcursor) {
            queryString = queryString + '&page=' + pcursor
        }

        if (label) {
            queryString = queryString + '&label=' + label
        }
        if (perPage) {
            queryString = queryString + '&perPage=' + perPage
        }
        return await axios.get(this.$conf.apiUrl + 'accounting-cost-center' + queryString)
            .then((res)=>{
                return res.data
            })
            .catch((e)=>{
                console.log(e)
            })
    },

    async addCenter(context , {data}){
       await axios.post(this.$conf.apiUrl + 'accounting-cost-center', data)
            .then((res)=>{
                return res
            })
            .catch((e)=>{
                const addErrorMessage = JSON.parse(e.request.responseText).errors[0]
                throw addErrorMessage
            })
    },

    async deleteCenter(context, data){
        await axios.delete(this.$conf.apiUrl + 'accounting-cost-center/'+data)
            .then((res)=>{
                return res
            })
            .catch((e)=>{
                const deleteErrorMessage = JSON.parse(e.request.responseText).errors.message
                throw deleteErrorMessage
            })

    },

    async editCenter(context, {data}){
        const label = {
            "accounting_cost_center":{
                "label": data.accounting_cost_center.label
            }
        }
        const id = data.id
        await axios.put(this.$conf.apiUrl + 'accounting-cost-center/'+id, label)
            .then((res)=>{
                return res
            })
            .catch((e)=>{
                console.log(e.request)
                if (e.request.status === 422){
                    const editError422 = JSON.parse(e.request.response).errors[0]
                    throw editError422
                }
                if (e.request.status === 500){
                    const editError = JSON.parse(e.request.responseText).errors.message
                    throw editError
                }


            })
    }
}

const mutations = {

    REPLACE_DATA(state, { list, data }) {
        state['centers_full'] = {}
        state['centers'] = []

        for (const id of list) {
            if (!state['centers'].includes(id)) {
                state['centers_full'][id] = data[id]
                state['centers'].push(id)
            }
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
