import axios from 'axios'

const UPDATE_META = 'UPDATE_META'

const state = {
    shipment_locations_full: {},
    shipment_locations_meta: {},
}

const getters = {}

const actions = {

    async fetchShipmentLocations(context, { queryString }) {
        return await axios
            .get(this.$conf.apiUrl + 'shipment-locations' + queryString)
            .then((response) => {
                const { shipment_locations: shipmentLocations, ...meta } = response.data;
                if (meta) context.commit(UPDATE_META, { data: meta })
                return shipmentLocations
            })
    },

    async load_shipment_locations({ dispatch }, { perPage, pcursor, locationCode }) {
        let queryString = '?'

        if (perPage) {
            queryString = queryString + '&perPage=' + perPage
        }

        if (pcursor) {
            queryString = queryString + '&page=' + pcursor
        }

        if (locationCode) {
            queryString = queryString + '&location_code=' + locationCode
        }



        return await dispatch('fetchShipmentLocations', { queryString }).then(async (items) => {
            return await dispatch('NORMALIZE_DATA', { items: items, refKey: 'id' })
        })
    },

    async postShipmentLocation(context, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'shipment-locations', data)
            .then((response) => {
                return response.data.shipment_location
            })
    },

    async getShipmentLocation(context, { id }) {
        return await axios
            .get(this.$conf.apiUrl + 'shipment-locations/'+ id)
            .then((response) => {
                return response.data.shipment_location
            })
    },

    async updateShipmentLocation({ commit }, { id, data }) {
        return await axios
            .put(this.$conf.apiUrl + 'shipment-locations/'+ id, data)
            .then((response) => {
                return response.data.shipment_location
            })
    },

    NORMALIZE_DATA({ commit }, { items, refKey }) {
        // Set an Array of ids
        const list = Object.freeze(items.map((o) => parseInt(o[refKey])))
        // Set an Object of Objects with full data, freezed for no reactivity
        const data = Object.freeze(this.$Helpers.array2Object(items, refKey))
        // Insert data to store
        commit('REPLACE_DATA', { list, data })
    },
}

const mutations = {

    REPLACE_DATA(state, { list, data }) {
        state['shipment_locations_full'] = {}
        state['shipment_locations'] = []

        for (const id of list) {
            if (!state['shipment_locations'].includes(id)) {
                state['shipment_locations_full'][id] = data[id]
                state['shipment_locations'].push(id)
            }
        }
    },

    [UPDATE_META](state, { data }) {
        state['shipment_locations_meta'] = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
