import axios from 'axios'
import Helpers from '@/plugins/Helpers'

const ADD_DATA = 'ADD_DATA'
const ADD_USER = 'ADD_USER'
const SET_CURRENT_USER = 'SET_CURRENT_USER'

const state = {
    current_user: {},

    users: [],
    users_full: {},
    usersPromise: Promise.resolve(),

    abortFetchUserByNameController: null,
}

const getters = {
    // @param type:String
    // @param id: Number|String
    obj_list_with_parent_id: (state) => () => {
        return state['users_full']
    },

    get_current_user: (state) => () => {
        return state['current_user']
    },

    //getter username by id
    getUserById: (state) => (supplierId) => {
        return state.suppliers_full.username[supplierId]
    },

    currentUserIsAdmin: (state) => () => {
        if (Object.keys(state['current_user']).length) {
            if (state['current_user'].roles.includes('superAdmin') || state['current_user'].roles.includes('admin')) {
                return true
            }
        }
        return false
    },
}

const actions = {
    async fetchAll({ dispatch }, { list, pcursor }) {
        return await axios
            .get(this.$conf.apiUrl + 'users?perPage=15&page=' + pcursor)
            .then((response) => {
                list = [...list, ...response.data.users]
                if (response.data.next_page_url && pcursor < response.data.last_page)
                    return dispatch('fetchAll', { list, pcursor: response.data.current_page + 1 })
                else return list
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
        /*if (users.data.pagination.nextCursor)
            return dispatch('fetchAll', { list, pcursor: users.data.pagination.nextCursor })
        else
            return list */
        //return list
    },

    async fetchAllProvider(context, { username }) {
        return await axios
            .get(this.$conf.apiUrl + 'suppliers?username=' + username)
            .then((response) => {
                return response.data.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async fetchAllUsers(context, { username }) {
        return await axios
            .get(this.$conf.apiUrl + 'users?username=' + username)
            .then((response) => {
                return response.data.users
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async fetchUserByName(context, { username }) {
        context.dispatch('cancelFetchUserByNameRequest')
        this.abortFetchUserByNameController = new AbortController()

        return await axios
            .get(this.$conf.apiUrl + 'users?exactUsername=' + username, {
                signal: this.abortFetchUserByNameController.signal,
            })
            .then((response) => {
                return response.data.users
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    cancelFetchUserByNameRequest() {
        if (this.abortFetchUserByNameController) this.abortFetchUserByNameController.abort()
    },

    async getOneUser(context, { id }) {
        return await axios
            .get(this.$conf.apiUrl + 'users/' + id)
            .then((response) => {
                return response.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async get_users(context, { pcursor, perPage }) {
        let queryString = '?'

        if (pcursor) {
            queryString = queryString + '&page=' + pcursor
        }

        if (perPage) {
            queryString = queryString + '&perPage=' + perPage
        }

        return await axios
            .get(this.$conf.apiUrl + 'users' + queryString)
            .then((response) => {
                return response.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
                return []
            })
    },

    async post(context, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'users', { user: data })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                let message = ''
                if (Helpers.isArray(error.response.data.errors)) {
                    error.response.data.errors.forEach((error) => {
                        message = message + error + '<br>'
                    })
                } else {
                    message = error.response.data.errors.message
                }
                context.dispatch(
                    'alerts/displayAlert',
                    {
                        type: 'error',
                        title: 'API Error',
                        message: this.$sanitize(message),
                        autoclose: true,
                        autocloseDelay: 8000,
                    },
                    { root: true }
                )
                throw error.response
            })
    },

    async create_user({ dispatch, commit }, { data }) {
        return await dispatch('post', { data: data }).then(async (data) => {
            commit(ADD_USER, { ...data.user })
            return data
        })
    },

    NORMALIZE_DATA({ commit }, { items, refKey }) {
        // Set an Array of ids
        const list = Object.freeze(items.map((o) => parseInt(o[refKey])))
        // Set an Object of Objects with full data, freezed for no reactivity
        const data = Object.freeze(this.$Helpers.array2Object(items, refKey))
        // Insert data to store
        commit('ADD_DATA', { list, data })
    },

    async updateUser(context, { id, user }) {
        return await axios
            .patch(this.$conf.apiUrl + 'users/' + id, { user })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                let message = ''
                if (Helpers.isArray(error.response.data.errors)) {
                    error.response.data.errors.forEach((error) => {
                        message = message + error + '<br>'
                    })
                } else {
                    message = error.response.data.errors.message
                }
                context.dispatch(
                    'alerts/displayAlert',
                    {
                        type: 'error',
                        title: 'API Error',
                        message: this.$sanitize(message),
                        autoclose: true,
                        autocloseDelay: 8000,
                    },
                    { root: true }
                )
                throw error.response
            })
    },
}

const mutations = {
    // @param list: Array
    // @param data: Object
    [ADD_DATA](state, { list, data }) {
        // Add entry if not existing
        for (const id of list) {
            if (!state['users'].includes(id)) {
                state['users_full'][id] = data[id]
                state['users'].push(id)
            }
        }
    },

    [ADD_USER](state, user) {
        state.users_full[user['id']] = user
    },

    // @param promise: Promise
    USERS_PROMISE(state, promise) {
        state['usersPromise'] = promise
    },

    [SET_CURRENT_USER](state, { user }) {
        state.current_user = user
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
