import axios from 'axios'
import Helpers from '../../../plugins/Helpers'

const ADD_HANDLING_TIMES = 'ADD_HANDLING_TIMES'

const state = {
    handling_times: {},
    handling_times_full: {},
    handling_times_promise: Promise.resolve(),
}

const getters = {
    getHandlingTimesTable: (state) => (supplierName) => {
        const table = {}
        const data = {}

        const handling_times = Object.assign([], state.handling_times_full[supplierName]).filter((n) => n)
        const handlingTimesByUser = Object.assign([], Helpers.groupBy(handling_times, 'user_id'))

        handlingTimesByUser.forEach((handling_times, handlingByUser) => {
            handling_times.forEach((handling_time) => {
                data[handling_time.client_id] = handling_time.handling_time
            })
            table[handlingByUser] = data
        })
        return Object.assign([], table)
    },
}

const actions = {
    async get_supplier_handling_times(context, { supplierName }) {
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/b2c/shipment-details/supplier/' + supplierName)
            .then((response) => {
                //api
                return response.data.suppliers_b2c_shipment_details
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async update(context, { supplierName, data }) {
        return await axios
            .put(this.$conf.apiUrl + 'suppliers/b2c/shipment-details/' + supplierName, {
                'shipment-details': data,
            })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    let message = ''
                    error.response.data.errors.forEach((error) => {
                        message = message + error + '<br>'
                    })
                    context.dispatch(
                        'alerts/displayAlert',
                        { type: 'error', title: 'API Error', message: this.$sanitize(message), autoclose: true },
                        { root: true }
                    )
                    //console.log(error.response.data);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                }
                //console.log(error.config);
                return false
            })
    },

    async load_handling_times({ dispatch, commit }, { supplierName }) {
        return await dispatch('get_supplier_handling_times', { supplierName: supplierName }).then(async (items) => {
            const promise = await dispatch('NORMALIZE_DATA', { items: items, refKey: 'id', supplierName })
            commit('LOAD_PROMISE', promise)
            return promise
        })
    },

    async update_handling_times({ dispatch, commit, rootState }, { supplierName, data }) {
        return await dispatch('update', { supplierName: supplierName, data: data }).then(async (data) => {
            if (data) {
                const supplierName = rootState.suppliers.active_supplier.name
                commit(ADD_HANDLING_TIMES, { handling_times: [...data.suppliers_b2c_shipment_details], supplierName })
                return data
            }
            return false
        })
    },

    NORMALIZE_DATA({ commit }, { items, refKey, supplierName }) {
        // Set an Array of ids
        const list = Object.freeze(items.map((o) => parseInt(o[refKey])))
        // Set an Object of Objects with full data, freezed for no reactivity
        const data = Object.freeze(this.$Helpers.array2Object(items, refKey))
        // Insert data to store
        commit('ADD_DATA', { list, data, supplierName })
    },
}

const mutations = {
    // @param type: String
    // @param list: Array
    // @param data: Object
    ADD_DATA(state, { list, data, supplierName }) {
        if (!Object.prototype.hasOwnProperty.call(state['handling_times'], supplierName)) {
            state['handling_times'][supplierName] = []
            state['handling_times_full'][supplierName] = {}
        }

        // Add entry if not existing
        for (const id of list) {
            if (!state['handling_times'][supplierName].includes(id)) {
                state['handling_times_full'][supplierName][id] = data[id]
                state['handling_times'][supplierName].push(id)
            }
        }
    },

    [ADD_HANDLING_TIMES](state, { handling_times, supplierName }) {
        for (const handling_time of handling_times) {
            if (!state['handling_times'][supplierName].includes(handling_time.id)) {
                state['handling_times'][supplierName].push(handling_time.id)
            }
            state['handling_times_full'][supplierName][handling_time.id] = handling_time
        }
    },

    // @param promise: Promise
    LOAD_PROMISE(state, promise) {
        state['handling_times_promise'] = promise
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
