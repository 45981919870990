import _merge from 'lodash/merge'

export default {
    install: (app) => {
        app.config.globalProperties.$Helpers = {
            array2Object(array, keyField) {
                return array.reduce((obj, item) => {
                    obj[item[keyField]] = item
                    return obj
                }, {})
            },

            inArray(val, arr) {
                return arr.indexOf(val) > -1
            },

            isArray(variable) {
                const toString = {}.toString
                const isArray = (variable) => {
                    return toString.call(variable) === '[object Array]'
                }

                return Array.isArray(variable) || isArray(variable)
            },

            pushOrSplice(val, arr) {
                const index = arr.indexOf(val)
                if (index == -1) return arr.push(val)
                return arr.splice(index, 1)
            },

            getParameterByName(name, url) {
                if (!url) url = window.location.href
                name = name.replace(/[[\]]/g, '\\$&')
                const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url)
                if (!results) return null
                if (!results[2]) return ''
                return decodeURIComponent(results[2].replace(/\+/g, ' '))
            },

            downloadFile(response, filename, type, extension) {
                // It is necessary to create a new blob object with mime-type explicitly set
                // otherwise only Chrome works like it should
                let newBlob
                if (type === 'application/json') {
                    const str = JSON.stringify(response.data)
                    const bytes = new TextEncoder().encode(str)
                    newBlob = new Blob([bytes], {
                        type: 'application/json;charset=utf-8',
                    })
                } else {
                    newBlob = new Blob([response.data], { type: type })
                }

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob)
                    return
                }

                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const data = window.URL.createObjectURL(newBlob)
                const link = document.createElement('a')
                link.href = data
                link.download = filename + '.' + extension
                link.click()
                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data)
                }, 100)
            },
        }
    },

    mergeFields(moduleA, moduleB) {
        return _merge({}, moduleA, moduleB)
    },

    isArray(variable) {
        const toString = {}.toString
        const isArray = (variable) => {
            return toString.call(variable) === '[object Array]'
        }

        return Array.isArray(variable) || isArray(variable)
    },

    array2Object(array, keyField) {
        return array.reduce((obj, item) => {
            obj[item[keyField]] = item
            return obj
        }, {})
    },

    stringifyObject(obj) {
        const placeholder = '____PLACEHOLDER____'
        const fns = []
        let json = JSON.stringify(
            obj,
            function (key, value) {
                if (typeof value === 'function') {
                    fns.push(value)
                    return placeholder
                }
                return value
            },
            2
        )
        json = json.replace(new RegExp('"' + placeholder + '"', 'g'), function () {
            return fns.shift()
        })
        return json
    },

    hashString(string) {
        let hash = 0,
            i,
            chr
        if (string.length === 0) return hash

        for (i = 0; i < string.length; i++) {
            chr = string.charCodeAt(i)
            hash = (hash << 5) - hash + chr
            hash |= 0
        }
        return hash
    },

    groupBy(data, key) {
        // `data` is an array of objects, `key` is the key (or property accessor) to group by
        // reduce runs this anonymous function on each element of `data` (the `item` parameter,
        // returning the `storage` parameter at the end
        return data.reduce(function (storage, item) {
            // get the first instance of the key by which we're grouping
            const group = item[key]

            // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
            storage[group] = storage[group] || []

            // add this item to its group within `storage`
            storage[group].push(item)

            // return the updated storage to the reduce function, which will then loop through the next
            return storage
        }, {}) // {} is the initial value of the storage
    },

    // scrollTop animation
    scrollTop(el, from = 0, to, duration = 500, endCallback) {
        if (!window.requestAnimationFrame) {
            window.requestAnimationFrame =
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.msRequestAnimationFrame ||
                function (callback) {
                    return window.setTimeout(callback, 1000 / 60)
                }
        }
        const difference = Math.abs(from - to)
        const step = Math.ceil((difference / duration) * 50)

        function scroll(start, end, step) {
            if (start === end) {
                endCallback && endCallback()
                return
            }

            let d = start + step > end ? end : start + step
            if (start > end) {
                d = start - step < end ? end : start - step
            }

            if (el === window) {
                window.scrollTo(d, d)
            } else {
                el.scrollTop = d
            }
            window.requestAnimationFrame(() => scroll(d, end, step))
        }
        scroll(from, to, step)
    },
}
