import { createRouter, createWebHistory } from 'vue-router'
import ViewUIPlus from 'view-ui-plus'

import routes from './routes'

export const router = (store) => {
    const router = createRouter({
        mode: 'history',
        history: createWebHistory(process.env.BASE_URL),
        scrollBehavior() {
            return { top: 0 }
        },
        routes: routes,
    })

    const setActiveUser = (supplier) => {
        if (supplier) {
            store.dispatch('suppliers/set_active_supplier', {
                id: store.getters['getId'](supplier),
                name: supplier,
            })
        }
    }

    router.beforeEach((to, from, next) => {
        ViewUIPlus.LoadingBar.start()
        //Util.title(to.meta.title);

        if (to.path == '/login') {
            // redirect to main route if a token is set
            if (store.getters['login/is_logged_in']) {
                next('/dashboard')
            } else {
                // Display Login
                next()
            }
        } else if (to.path == '/logout') {
            store.dispatch('login/logout').then(() => {
                next('/login')
            })
        } else if (to.path == '/') {
            next('/dashboard')
        } else {
            // Main route, get user info
            if (store.getters['login/is_logged_in']) {
                if (Object.keys(store.getters['users/get_current_user']()).length === 0) {
                    store.dispatch('login/user_profile').then(() => {
                        // Always ensure that suppliers list is loaded before accessing the application
                        if (Object.keys(store.state.suppliers.suppliers_full).length === 0) {
                            store.dispatch('suppliers/load_suppliers').then(() => {
                                setActiveUser(to.query.supplier)
                                next()
                            })
                        } else {
                            setActiveUser(to.query.supplier)
                            next()
                        }
                    })
                } else {
                    // Always ensure that suppliers list is loaded before accessing the application
                    if (Object.keys(store.state.suppliers.suppliers_full).length === 0) {
                        store.dispatch('suppliers/load_suppliers').then(() => {
                            setActiveUser(to.query.supplier)
                            next()
                        })
                    } else {
                        setActiveUser(to.query.supplier)
                        next()
                    }
                }
            } else {
                store.dispatch('login/user_profile').then((user_profile) => {
                    // Display main route
                    if (store.getters['login/is_logged_in']) {
                        // store user profile
                        store.commit('COLLECT_USER_PROFILE', user_profile)
                        if (Object.keys(store.state.suppliers.suppliers_full).length === 0) {
                            store.dispatch('suppliers/load_suppliers').then(() => {
                                setActiveUser(to.query.supplier)
                                next()
                            })
                        } else {
                            setActiveUser(to.query.supplier)
                            next()
                        }
                    } else {
                        // invalid token, logout
                        store.dispatch('login/logout').then(() => {
                            next('/login')
                        })
                    }
                })
            }
        }
    })

    router.afterEach((to) => {
        document.title = 'Lulubuy BackOffice - ' + to.meta.title
        ViewUIPlus.LoadingBar.finish()
        window.scrollTo(0, 0)
    })

    return router
}
