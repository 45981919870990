import axios from 'axios'
import Helpers from '@/plugins/Helpers'

const state = {}

const getters = {}

const actions = {
    async fetchAll(context, { queryString }) {
        return await axios
            .get(this.$conf.apiUrl + 'orders/b2c/orders' + queryString)
            .then((response) => {
                return response.data.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async fetch(context, { orderId, sellerSku }) {
        return await axios
            .get(this.$conf.apiUrl + 'orders/b2c/orders/' + orderId + '/' + sellerSku)
            .then((response) => {
                return response.data.data
            })
            .catch((error) => {
                console.log(error, 'Unable to obtain data')
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    let message = ''
                    if (Helpers.isArray(error.response.data.errors)) {
                        error.response.data.errors.forEach((error) => {
                            message = message + error + '<br>'
                        })
                    } else {
                        message = error.response.data.errors.message
                    }

                    context.dispatch(
                        'alerts/displayAlert',
                        { type: 'error', title: 'API Error', message: this.$sanitize(message) },
                        { root: true }
                    )
                    //console.log(error.response.data);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                }
                //console.log(error.config);
                return false
            })
    },

    async getClientData(context, { orderId }) {
        return await axios
            .get(this.$conf.apiUrl + 'orders/b2c/orders/' + orderId + '/client_data')
            .then((response) => {
                return response.data.data
            })
            .catch((error) => {
                console.log(error, 'Unable to obtain data')
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    let message = ''
                    if (Helpers.isArray(error.response.data.errors)) {
                        error.response.data.errors.forEach((error) => {
                            message = message + error + '<br>'
                        })
                    } else {
                        message = error.response.data.errors.message
                    }

                    context.dispatch(
                        'alerts/displayAlert',
                        { type: 'error', title: 'API Error', message: this.$sanitize(message) },
                        { root: true }
                    )
                    //console.log(error.response.data);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                }
                return false
            })
    },

    async list({ dispatch }, { daterange }) {
        let queryString = '?'

        if (daterange) {
            queryString = queryString + 'created_at=' + JSON.stringify(daterange)
        }

        const items = await dispatch('fetchAll', { queryString })
        return items
    },

    async synchronize(context, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'orders/b2c/orders/synchronize', data)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    let message = ''
                    if (Helpers.isArray(error.response.data.errors)) {
                        error.response.data.errors.forEach((error) => {
                            message = message + error + '<br>'
                        })
                    } else {
                        message = error.response.data.errors.message
                    }

                    context.dispatch(
                        'alerts/displayAlert',
                        { type: 'error', title: 'API Error', message: this.$sanitize(message), autoclose: true },
                        { root: true }
                    )
                    //console.log(error.response.data);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                }
                //console.log(error.config);
                return false
            })
    },

    async send_summary_report(context, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'orders/b2c/orders/send_summary_report', data)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    let message = ''
                    if (Helpers.isArray(error.response.data.errors)) {
                        error.response.data.errors.forEach((error) => {
                            message = message + error + '<br>'
                        })
                    } else {
                        message = error.response.data.errors.message
                    }

                    context.dispatch(
                        'alerts/displayAlert',
                        { type: 'error', title: 'API Error', message: this.$sanitize(message), autoclose: true },
                        { root: true }
                    )
                    //console.log(error.response.data);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                }
                //console.log(error.config);
                return false
            })
    },

    async retry(context, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'orders/b2c/orders/retry', data)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    let message = ''
                    if (Helpers.isArray(error.response.data.errors)) {
                        error.response.data.errors.forEach((error) => {
                            message = message + error + '<br>'
                        })
                    } else {
                        message = error.response.data.errors.message
                    }

                    context.dispatch(
                        'alerts/displayAlert',
                        { type: 'error', title: 'API Error', message: this.$sanitize(message) },
                        { root: true }
                    )
                    //console.log(error.response.data);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                }
                //console.log(error.config);
                return false
            })
    },

    async get({ dispatch }, { orderId, sellerSku }) {
        const item = await dispatch('fetch', { orderId, sellerSku })

        return item
    },

    async sendCustomOrder(context, { orderId, data }) {
        return await axios
            .post(this.$conf.apiUrl + 'orders/b2c/orders/' + orderId, data)
            .then((response) => {
                return response.data.data
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    let message = ''
                    if (Helpers.isArray(error.response.data.errors)) {
                        error.response.data.errors.forEach((error) => {
                            message = message + error + '<br>'
                        })
                    } else {
                        message = error.response.data.errors.message
                    }

                    context.dispatch(
                        'alerts/displayAlert',
                        { type: 'error', title: 'API Error', message: this.$sanitize(message) },
                        { root: true }
                    )
                    //console.log(error.response.data);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                }
                //console.log(error.config);
                return false
            })
    },

    async sendManualOrder(context, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'orders/b2c/orders/manual', data)
            .then((response) => {
                return response.data.data
            })
            .catch((error) => {
               let message = ''
                if (Helpers.isArray(error.response.data.errors)) {
                    error.response.data.errors.forEach((error) => {
                        message = message + error + '<br>'
                    })
                } else {
                    message = error.response.data.errors.message
                }
                context.dispatch(
                    'alerts/displayAlert',
                    { type: 'error', title: 'API Error', message: this.$sanitize(message), autoclose: true },
                    { root: true }
                )
                throw error.response
            })
    },

    async generateETDLabel(context, { orderId, data }) {
        return await axios
            .post(this.$conf.apiUrl + 'etd-logistic/' + orderId + '/create-shipment-label', data)
            .then((response) => {
                return response.data.data
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    let message = ''
                    if (Helpers.isArray(error.response.data.errors)) {
                        error.response.data.errors.forEach((error) => {
                            message = message + error + '<br>'
                        })
                    } else {
                        message = error.response.data.errors.message
                    }

                    if (message == '') {
                        message = error.response.data.message
                    }

                    context.dispatch(
                        'alerts/displayAlert',
                        { type: 'error', title: 'API Error', message: this.$sanitize(message) },
                        { root: true }
                    )
                    //console.log(error.response.data);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                }
                //console.log(error.config);
                return false
            })
    }
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
