import axios from 'axios'
import Helpers from '@/plugins/Helpers'

const ADD_SUPPLIER = 'ADD_SUPPLIER'
const ADD_DETAILS = 'ADD_DETAILS'
const ADD_TRANSPORTS = 'ADD_TRANSPORTS'

const STORE_DATA = 'STORE_DATA'

const state = {
    active_supplier: { id: null, name: null },
    suppliers: [],
    suppliers_named: [],
    suppliers_full: {},
    suppliers_by_name: {},
    suppliersPromise: Promise.resolve(),

    suppliers_details: [],
    suppliers_details_full: {},
    suppliersDetailsPromise: Promise.resolve(),

    suppliers_transports: [],
    suppliers_transports_full: {},
    suppliersTransportPromise: Promise.resolve(),

    suppliers_contact: [],
    suppliers_contact_full: {},
    suppliersContactPromise: Promise.resolve(),

    suppliers_contact_details: {}
}

const getters = {
    getActiveSupplier: (state) => () => {
        return state.active_supplier
    },

    getSupplierIdByName: (state) => (supplierName) => {
        return state.suppliers_by_name[supplierName].id
    },

    getSupplierData: (state) => (supplierId) => {
        return state.suppliers_full[supplierId]
    },

    getSupplierDetails: (state) => (supplierId) => {
        return state.suppliers_details_full[supplierId].details
    },

    getSupplierTransports: (state) => (supplierId) => {
        return state.suppliers_details_full[supplierId].transport
    },

    getContactNameById: (state) => (id) => {
        return state.suppliers_contact_details[id].contact_name
    },
}

const actions = {
    set_active_supplier({ state, commit }, obj) {
        if (state.active_supplier.id != obj.id) commit('SET_ACTIVE_SUPPLIER', obj)
    },

    async fetchAll({ dispatch }, { list, pcursor, username, withAllDetails, withDetails, companyId }) {
        const queryParams = {}

        if (username) {
            queryParams.username = username
        }

        if (pcursor) {
            queryParams.page = pcursor
        }

        if (withAllDetails) {
            queryParams.withAllDetails = true
        }

        if (withDetails) {
            queryParams.withDetails = true
        }

        if (companyId) {
            queryParams.fromCompany = companyId
        }

        if (!list) {
            list = []
        }

        queryParams.perPage = 50

        return await axios
            .get(this.$conf.apiUrl + 'suppliers', { params: queryParams })
            .then((response) => {
                const totalPages = response.data.meta.last_page
                list = [...list, ...response.data.data]

                return Promise.all(
                    [...Array(totalPages).keys()].map((page) => {
                        queryParams.page = page + 1
                        if (queryParams.page === 1) {
                            return
                        }
                        return axios.get(`${this.$conf.apiUrl}suppliers`, { params: queryParams })
                    })
                ).then(
                    axios.spread((...responses) => {
                        responses.forEach((response) => {
                            if (response) list = [...list, ...response.data.data]
                        })

                        return list
                    })
                )
            })
    },

    async post(context, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'users', { user: data })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                let message = ''
                if (Helpers.isArray(error.response.data.errors)) {
                    error.response.data.errors.forEach((error) => {
                        message = message + error + '<br>'
                    })
                } else {
                    message = error.response.data.errors.message
                }
                context.dispatch(
                    'alerts/displayAlert',
                    {
                        type: 'error',
                        title: 'API Error',
                        message: this.$sanitize(message),
                        autoclose: true,
                        autocloseDelay: 8000
                    },
                    { root: true }
                )
                throw error.response
            })
    },

    async load_suppliers({ dispatch, commit }) {
        return await dispatch('fetchAll', { list: [], pcursor: 1, withDetails: true, companyId: 1 }).then(async (suppliers) => {
            let promise = await dispatch('NORMALIZE_DATA', { items: suppliers, refKey: 'id' })
            commit('SUPPLIERS_PROMISE', promise)
            return promise
        })
    },

    async load_supplier_details({ dispatch }, { username }) {
        return await dispatch('fetchAll', { username, withAllDetails: true, companyId: 1 }).then(async (suppliers) => {
            return await dispatch('NORMALIZE_DATA', { items: suppliers, refKey: 'id' })
        })
    },

    async get_suppliers(context, { searchBy, searchValue, pcursor, perPage, deliveryLocation, withEnabledClients, withPurchaseOrderDetail, withDetails, withTransports, fromCompany, username, withPrFileStatusEnabled }) {
        let queryParams = {}

        if (searchBy && searchValue) {
            queryParams[searchBy] = searchValue
        }

        if (pcursor) {
            queryParams.page = pcursor
        }

        if (withPrFileStatusEnabled) {
            queryParams.withPrFileStatusEnabled = withPrFileStatusEnabled
        }

        return await axios
            .get(this.$conf.apiUrl + 'suppliers', {
                params: {
                    perPage,
                    deliveryLocation,
                    withEnabledClients,
                    withPurchaseOrderDetail,
                    withDetails,
                    username,
                    withTransports,
                    fromCompany,
                    ...queryParams
                }
            })
            .then((response) => {
                return response.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
                return []
            })
    },

    async load_supplier_warehouses(commit, { username }) {
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/' + username + '/warehouses')
            .then((response) => {
                return response.data.supplier_warehouses
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async save_bank_details({ rootState }, { data, bankDetailId }) {
        const supplierName = rootState.suppliers.active_supplier.name
        if (bankDetailId) {
            return await axios
                .put(this.$conf.apiUrl + 'suppliers/' + supplierName + '/bank_details/' + bankDetailId, { bank_details: data })
                .then(async (response) => {
                    if (response) {
                        return response.data.bank_details
                    }
                    return false
                })
        } else {
            return await axios
                .post(this.$conf.apiUrl + 'suppliers/' + supplierName + '/bank_details', { bank_details: data })
                .then(async (response) => {
                    if (response) {
                        return response.data.bank_details
                    }
                    return false
                })
        }
    },

    async get_one_bank_detail({ rootState }, { bankDetailId }) {
        const supplierName = rootState.suppliers.active_supplier.name
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/' + supplierName + '/bank_details/' + bankDetailId)
            .then((response) => {
                return response.data.bank_details
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async load_supplier_bank_details({ rootState }) {
        const supplierName = rootState.suppliers.active_supplier.name
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/' + supplierName + '/bank_details')
            .then((response) => {
                return response.data.bank_details
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async delete_bank_detail({ rootState }, { bankDetailId }) {
        const supplierName = rootState.suppliers.active_supplier.name
        return await axios
            .delete(this.$conf.apiUrl + 'suppliers/' + supplierName + '/bank_details/' + bankDetailId)
            .then(async (response) => {
                if (response) {
                    return response.data
                }
                return false
            })
    },

    async save_contact_details({ rootState }, { data, contactDetailId }) {
        const supplierName = rootState.suppliers.active_supplier.name
        if (contactDetailId) {
            return await axios
                .put(this.$conf.apiUrl + 'suppliers/contact/details/' + supplierName + '/' + contactDetailId, data)
                .then(async (response) => {
                    if (response) {
                        return response.data.suppliers_contact_details
                    }
                    return false
                })
        } else {
            return await axios
                .post(this.$conf.apiUrl + 'suppliers/contact/details/' + supplierName, data)
                .then(async (response) => {
                    if (response) {
                        return response.data.suppliers_contact_details
                    }
                    return false
                })
        }
    },

    async get_one_contact_detail({ rootState }, { contactDetailId }) {
        const supplierName = rootState.suppliers.active_supplier.name
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/contact/details/' + supplierName + '/' + contactDetailId)
            .then((response) => {
                return response.data.suppliers_contact_details
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async fetch_supplier_contact_details({ rootState }) {
        const supplierName = rootState.suppliers.active_supplier.name
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/contact/details/' + supplierName + '')
            .then((response) => {
                return response.data.suppliers_contact_details
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async load_contact_details({ dispatch, commit }) {
        return await dispatch('fetch_supplier_contact_details').then(async (data) => {
            commit(STORE_DATA, { items: data, refKey: 'id' })
            return data
        })
    },

    async delete_contact_detail({ rootState }, { contactDetailId }) {
        const supplierName = rootState.suppliers.active_supplier.name
        return await axios
            .delete(this.$conf.apiUrl + 'suppliers/contact/details/' + supplierName + '/' + contactDetailId)
            .then(async (response) => {
                if (response) {
                    return response.data
                }
                return false
            })
    },

    async load_supplier_contact_com({ rootState }) {
        const supplierName = rootState.suppliers.active_supplier.name
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/contact/communication/' + supplierName)
            .then((response) => {
                return response.data.suppliers_contact_communications
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async post_contact_com({ rootState }, { data }) {
        const supplierName = rootState.suppliers.active_supplier.name
        return await axios
            .post(this.$conf.apiUrl + 'suppliers/contact/communication/' + supplierName, data)
            .then(async (response) => {
                if (response) {
                    return response.data.suppliers_contact_communications
                }
                return false
            })
    },

    async create_supplier({ dispatch, commit }, { data }) {
        return await dispatch('post', { data: data }).then(async (data) => {
            commit(ADD_SUPPLIER, { ...data.user })
            return data
        })
    },

    async generate_directories({ commit }, { supplierName }) {
        return await axios.post(this.$conf.apiUrl + 'suppliers/' + supplierName + '/generate-directories').then(async (response) => {
            return response.data
        })
    },

    async update_data({ commit }, { supplierName, data }) {
        return await axios.put(this.$conf.apiUrl + 'suppliers/' + supplierName, data).then(async (data) => {
            if (data) {
                commit(ADD_SUPPLIER, { ...data.user })
                return data
            }
            return false
        })
    },

    async update_details({ commit, rootState }, { supplierName, data }) {
        return await axios.put(this.$conf.apiUrl + 'suppliers/' + supplierName + '/details', data).then(async (response) => {
            if (response) {
                const supplierName = rootState.suppliers.active_supplier.name
                const supplierId = rootState.suppliers.active_supplier.id
                commit(ADD_DETAILS, { details: response.data.supplier_detail, supplierName: supplierName, id: supplierId })
                return response.data
            }
            return false
        })
    },

    async update_transports({ commit, rootState }, { supplierName, data }) {
        return await axios.put(this.$conf.apiUrl + 'suppliers/' + supplierName + '/transports', data).then(async (response) => {
            if (response) {
                const supplierName = rootState.suppliers.active_supplier.name
                commit(ADD_TRANSPORTS, { transports: response.data.supplier_transport, supplierName })
                return response.data
            }
            return false
        })
    },

    async create_purchase_order_details({ commit, rootState }, { supplier_id, data }) {
        return await axios.post(this.$conf.apiUrl + 'users/' + supplier_id + '/purchase-order-details' , data).then(async (response) => {
            if (response) {
                return response.data
            }
            return false
        })
    },

    async update_purchase_order_details({ commit, rootState }, { supplier_id, pod_id, data }) {
        return await axios.put(this.$conf.apiUrl + 'users/' + supplier_id + '/purchase-order-details/' +  pod_id , data).then(async (response) => {
            if (response) {
                return response.data
            }
            return false
        })
    },

    async get_supplier_warehouse({ rootState }, { warehouseId }) {
        const supplierName = rootState.suppliers.active_supplier.name
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/' + supplierName + '/warehouses/' + warehouseId)
            .then((response) => {
                return response.data.supplier_warehouse
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async save_warehouse({ rootState }, { data, warehouseId }) {
        const supplierName = rootState.suppliers.active_supplier.name
        if (warehouseId) {
            return await axios
                .put(this.$conf.apiUrl + 'suppliers/' + supplierName + '/warehouses/' + warehouseId, data)
                .then(async (response) => {
                    if (response) {
                        return response.data
                    }
                    return false
                })
        } else {
            return await axios.post(this.$conf.apiUrl + 'suppliers/' + supplierName + '/warehouses', data).then(async (response) => {
                if (response) {
                    return response.data
                }
                return false
            })
        }
    },

    async delete_warehouse({ rootState }, { warehouseId }) {
        const supplierName = rootState.suppliers.active_supplier.name
        return await axios.delete(this.$conf.apiUrl + 'suppliers/' + supplierName + '/warehouses/' + warehouseId).then(async (response) => {
            if (response) {
                return response.data
            }
            return false
        })
    },

    async uploadNDAFile({ rootState }, { data }) {
        const supplierName = rootState.suppliers.active_supplier.name
        return await axios
            .post(this.$conf.apiUrl + 'suppliers/' + supplierName + '/details/import_nda', data)
            .then((response) => {
                if (response) {
                    return response.data
                }
            })
            .catch((e) => {
                console.log(e, 'Unable to upload PDF file')
            })
    },

    async download_NDA_file({ rootState }) {
        const supplierName = rootState.suppliers.active_supplier.name
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/' + supplierName + '/details/download_nda', { responseType: 'arraybuffer' })
            .then((response) => response)
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    NORMALIZE_DATA({ commit }, { items, refKey }) {
        // Set an Array of ids
        const list = Object.freeze(items.map((o) => parseInt(o[refKey])))
        // Set an Object of Objects with full data, freezed for no reactivity
        const data = Object.freeze(this.$Helpers.array2Object(items, refKey))
        // Insert data to store
        commit('ADD_DATA', { list, data })
    },
}

// mutations
const mutations = {
    SET_ACTIVE_SUPPLIER(state, obj) {
        state.active_supplier.id = obj.id
        state.active_supplier.name = obj.name
    },

    [ADD_SUPPLIER](state, supplier) {
        state.suppliers_full[supplier['id']] = supplier
    },

    [ADD_DETAILS](state, supplier) {
        state.suppliers_details_full[supplier['id']] = supplier
    },

    [ADD_TRANSPORTS](state, supplier) {
        state.suppliers_transports_full[supplier['name']] = supplier
    },

    // @param list: Array
    // @param data: Object
    ADD_DATA(state, { list, data }) {
        // Add entry if not existing
        for (const id of list) {
            if (!state['suppliers'].includes(id)) {
                state['suppliers_full'][id] = data[id]
                state['suppliers'].push(id)
                state['suppliers_named'].push(data[id]['username'])
                state['suppliers_by_name'][data[id]['username']] = data[id]
            }

            if (Object.prototype.hasOwnProperty.call(data[id], 'details')) {
                if (!state['suppliers_details'].includes(id)) {
                    state['suppliers_details_full'][id] = data[id]
                    state['suppliers_details'].push(id)
                }
            }
        }
    },

    // @param promise: Promise
    SUPPLIERS_PROMISE(state, promise) {
        state['suppliersPromise'] = promise
    },

    SUPPLIERS_DETAILS_PROMISE(state, promise) {
        state['suppliersDetailsPromise'] = promise
    },

    SUPPLIERS_TRANSPORTS_PROMISE(state, promise) {
        state['suppliersTransportPromise'] = promise
    },

    SUPPLIERS_CONTACT_PROMISE(state, promise) {
        state['suppliersContactPromise'] = promise
    },

    [STORE_DATA](state, { items, refKey }) {
        state['suppliers_contact_details'] = {}
        items.forEach((item) => {
            state['suppliers_contact_details'][item[refKey]] = item
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
