<template>
    <div id="app" v-cloak>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'app',
        created() {},
    }
</script>

<style>
    @import './assets/styles/colors.css';

    @font-face {
        font-family: Montserrat;
        src: url("./assets/fonts/Montserrat-VariableFont_wght.ttf"),
        url("./assets/fonts/Montserrat-Italic-VariableFont_wght.ttf");
    }

    @import url('//fonts.googleapis.com/css?family=Ubuntu:300,400,400i,700|Open+Sans:400,700');

    [v-cloak] {
        display: none;
    }

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    html {
        background: #fcfcfc;
    }

    body {
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif !important;
        background-color: var(--color-grayscale-secondary);
    }

    #app {
        background: #fcfcfc;
        position: relative;
    }
</style>
