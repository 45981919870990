const getters = {
    // @return Array
    list: (state, getters, rootState) => {
        return rootState.suppliers.suppliers
    },

    // @param id: Number
    // @return String
    item: (state, getters, rootState) => (id) => {
        const item = rootState.suppliers.suppliers_full[id]
        return item
    },

    // @param id: Number
    // @return String
    getId: (state, getters, rootState) => (name) => {
        const id = rootState.suppliers.suppliers_by_name[name].id
        return id
    },
}

export default {
    getters,
}
