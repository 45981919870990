const parse = (value, fallback) => {
    if (typeof value === 'undefined') {
        return fallback
    }
    switch (typeof fallback) {
        case 'boolean':
            return !!JSON.parse(value)
        case 'number':
            return JSON.parse(value)
        default:
            return value
    }
}

const feature = (name) => {
    return config.features[name]
}

const config = {
    apiUrl: parse(process.env.VUE_APP_API_URL, 'http://localhost:8081'),

    features: {
        example: parse(process.env.VUE_APP_FEATURE_EXAMPLE, false),
    },
}

export { config }

export default {
    install: (app) => {
        app.config.globalProperties.$appConfig = config
        app.config.globalProperties.$feature = feature
    },
}
