import axios from 'axios'
import Helpers from '../../../plugins/Helpers'

const ADD_SHIPPING_COSTS = 'ADD_SHIPPING_COSTS'

const state = {
    shipping_costs: {},
    shipping_costs_full: {},
    shipping_costs_promise: Promise.resolve(),
}

const getters = {
    getShippingCostsTable: (state) => (supplierName) => {
        if (
            Object.keys(state.shipping_costs_full).length === 0 ||
            !Object.prototype.hasOwnProperty.call(state.shipping_costs_full, supplierName)
        ) {
            return []
        }
        const shippingCosts = Object.assign([], state.shipping_costs_full[supplierName]).filter((n) => n)
        const shippingCostsGroupByWeight = Object.assign([], Helpers.groupBy(shippingCosts, 'shipping_weight_id'))
        const table = {}
        shippingCostsGroupByWeight.forEach((shippingCosts, shippingWeightId) => {
            const data = {}
            let shippingWeight = ''
            shippingCosts.forEach((shippingCost) => {
                if (shippingWeight == '') {
                    shippingWeight = (shippingCost.from_weight <= 0 ? '[' : ']') + shippingCost.from_weight + '-' + shippingCost.to_weight + '] kg (' + shippingCost.shipping_weight_id + ')'
                }
                data[shippingCost.country_iso2.toLowerCase()] = shippingCost.price
            })
            data['shipping_weight_id'] = shippingWeightId
            data['shipping_weight'] = shippingWeight

            table[shippingWeightId] = data
        })
        return Object.assign([], table)
    },
}

const actions = {
    async get_supplier_shipping_costs(context, { supplierName }) {
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/b2c/shipping-costs/' + supplierName)
            .then((response) => {
                return response.data.suppliers_b2c_shipping_costs
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async update(context, { supplierName, data }) {
        return await axios
            .put(this.$conf.apiUrl + 'suppliers/b2c/shipping-costs/' + supplierName, {
                shipping_costs: data,
            })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    let message = ''
                    error.response.data.errors.forEach((error) => {
                        message = message + error + '<br>'
                    })
                    context.dispatch(
                        'alerts/displayAlert',
                        { type: 'error', title: 'API Error', message: this.$sanitize(message) },
                        { root: true }
                    )
                    //console.log(error.response.data);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                }
                //console.log(error.config);
                return false
            })
    },

    async load_shipping_costs({ dispatch, commit }, { supplierName }) {
        return await dispatch('get_supplier_shipping_costs', { supplierName: supplierName }).then(async (items) => {
            const promise = await dispatch('NORMALIZE_DATA', { items: items, refKey: 'id', supplierName })
            commit('LOAD_PROMISE', promise)
            return promise
        })
    },

    async update_shipping_costs({ dispatch, commit, rootState }, { supplierName, data }) {
        return await dispatch('update', { supplierName: supplierName, data: data }).then(async (data) => {
            if (data) {
                const supplierName = rootState.suppliers.active_supplier.name
                commit(ADD_SHIPPING_COSTS, { shipping_costs: [...data.suppliers_b2c_shipping_costs], supplierName })
                return data
            }
            return false
        })
    },

    NORMALIZE_DATA({ commit }, { items, refKey, supplierName }) {
        // Set an Array of ids
        const list = Object.freeze(items.map((o) => parseInt(o[refKey])))
        // Set an Object of Objects with full data, freezed for no reactivity
        const data = Object.freeze(this.$Helpers.array2Object(items, refKey))
        // Insert data to store
        commit('ADD_DATA', { list, data, supplierName })
    },
}

const mutations = {
    // @param type: String
    // @param list: Array
    // @param data: Object
    ADD_DATA(state, { list, data, supplierName }) {
        if (!Object.prototype.hasOwnProperty.call(state['shipping_costs'], supplierName)) {
            state['shipping_costs'][supplierName] = []
            state['shipping_costs_full'][supplierName] = {}
        }

        // Add entry if not existing
        for (const id of list) {
            if (!state['shipping_costs'][supplierName].includes(id)) {
                state['shipping_costs_full'][supplierName][id] = data[id]
                state['shipping_costs'][supplierName].push(id)
            }
        }
    },

    [ADD_SHIPPING_COSTS](state, { shipping_costs, supplierName }) {
        for (const shipping_cost of shipping_costs) {
            if (!state['shipping_costs'][supplierName].includes(shipping_cost.id)) {
                state['shipping_costs'][supplierName].push(shipping_cost.id)
            }
            state['shipping_costs_full'][supplierName][shipping_cost.id] = shipping_cost
        }
    },

    // @param promise: Promise
    LOAD_PROMISE(state, promise) {
        state['shipping_costs_promise'] = promise
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
