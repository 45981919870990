import axios from 'axios'

const ADD_SHIPPING_WEIGHT = 'ADD_SHIPPING_WEIGHT'

const state = {
    shipping_weights: [],
    shipping_weights_full: {},
    shipping_weights_promise: Promise.resolve(),
}

const getters = {
    // @param id: Int
    getById: (state) => (id) => {
        return Object.assign([], state.shipping_weights_full)
            .filter((n) => n)
            .find((shipping_weight) => shipping_weight.id === id)
    },
}

const actions = {
    async fetchAll({ dispatch }, { list, pcursor }) {
        return await axios
            .get(this.$conf.apiUrl + 'shipping-weights?page=' + pcursor)
            .then((response) => {
                list = [...list, ...response.data.shipping_weights]
                if (response.data.next_page_url && pcursor < response.data.last_page)
                    return dispatch('fetchAll', { list, pcursor: response.data.current_page + 1 })
                else return list
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async post(context, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'shipping-weights', data)
            .then((response) => {
                return response.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async load_shipping_weights({ dispatch, commit }) {
        return await dispatch('fetchAll', { list: [], pcursor: 1 }).then(async (items) => {
            const promise = await dispatch('NORMALIZE_DATA', { items: items, refKey: 'id' })
            commit('LOAD_PROMISE', promise)
            return promise
        })
    },

    async create_shipping_weight({ dispatch, commit }, { data }) {
        return await dispatch('post', { data: data }).then(async (data) => {
            commit(ADD_SHIPPING_WEIGHT, { ...data.shipping_weight })
            return data
            /*let promise = await dispatch('NORMALIZE_DATA', {items : items, refKey : 'id' })
            commit('LOAD_PROMISE', promise);
            return promise*/
        })
    },

    NORMALIZE_DATA({ commit }, { items, refKey }) {
        // Set an Array of ids
        const list = Object.freeze(items.map((o) => parseInt(o[refKey])))
        // Set an Object of Objects with full data, freezed for no reactivity
        const data = Object.freeze(this.$Helpers.array2Object(items, refKey))
        // Insert data to store
        commit('ADD_DATA', { list, data })
    },
}

const mutations = {
    // @param list: Array
    // @param data: Object
    ADD_DATA(state, { list, data }) {
        // Add entry if not existing
        for (const id of list) {
            if (!state['shipping_weights'].includes(id)) {
                state['shipping_weights_full'][id] = data[id]
                state['shipping_weights'].push(id)
            }
        }
    },

    [ADD_SHIPPING_WEIGHT](state, shipping_weight) {
        state.shipping_weights_full[shipping_weight['id']] = shipping_weight
    },

    // @param promise: Promise
    LOAD_PROMISE(state, promise) {
        state['shipping_weights_promise'] = promise
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
