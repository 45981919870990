import axios from 'axios'

const ADD_DATA = 'ADD_DATA'
const ADD_CONFIGURATION = 'ADD_CONFIGURATION'
const SET_CURRENT_CONFIGURATION = 'SET_CURRENT_CONFIGURATION'

const state = {
    current_configuration: {},

    configurations: [],
    configurations_full: {},
    configurations_by_key_full: {},
    configurationsPromise: Promise.resolve(),
}

const getters = {
    // @param type:String
    // @param id: Number|String
    obj_list_with_parent_id: (state) => () => {
        return state['configurations_full']
    },

    get_current_configuration: (state) => () => {
        return state['current_configuration']
    },

    getConfigurationById: (state) => (id) => {
        return state.configurations_full[id]
    },

    getConfigurationByKey: (state) => (key) => {
        return state.configurations_by_key_full[key]
    },

    currentConfigurationIsAdmin: (state) => () => {
        if (Object.keys(state['current_configuration']).length) {
            if (state['current_configuration'].roles.includes('superAdmin') || state['current_configuration'].roles.includes('admin')) {
                return true
            }
        }
        return false
    },
}

const actions = {
    async getOneConfiguration(context, { id }) {
        return await axios
            .get(this.$conf.apiUrl + 'resources/configurations/' + id)
            .then((response) => {
                return response.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async get_configurations({ dispatch, commit }) {
        return await axios
            .get(this.$conf.apiUrl + 'resources/configurations')
            .then(async (response) => {
                await dispatch('NORMALIZE_DATA', { items: response.data.data, refKey: 'id' })

                return response.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
                return []
            })
    },

    async post(context, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'resources/configurations', { configuration: data })
            .then((response) => {
                return response.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async create_configuration({ dispatch, commit }, { data }) {
        return await dispatch('post', { data: data }).then(async (data) => {
            commit(ADD_CONFIGURATION, { ...data.configuration })
            return data
        })
    },

    NORMALIZE_DATA({ commit }, { items, refKey }) {
        // Set an Array of ids
        const list = Object.freeze(items.map((o) => parseInt(o[refKey])))
        // Set an Object of Objects with full data, freezed for no reactivity
        const data = Object.freeze(this.$Helpers.array2Object(items, refKey))
        // Insert data to store
        commit('ADD_DATA', { list, data })
    },

    async updateConfiguration({ commit }, { id, configuration }) {
        return await axios
            .put(this.$conf.apiUrl + 'resources/configurations/' + id, { configuration })
            .then((response) => {
                commit(ADD_CONFIGURATION, { ...response.data.data })
                return response.data
            })
            .catch((e) => {
                console.log(e, 'Unable to save configuration products condition')
            })
    },
}

const mutations = {
    // @param list: Array
    // @param data: Object
    [ADD_DATA](state, { list, data }) {
        // Add entry if not existing
        for (const id of list) {
            if (!state['configurations'].includes(id)) {
                state['configurations_full'][id] = data[id]
                state['configurations_by_key_full'][data[id]['type'] + '.' + data[id]['key']] = data[id]
                state['configurations'].push(id)
            }
        }
    },

    [ADD_CONFIGURATION](state, configuration) {
        state.configurations_full[configuration['id']] = configuration
        state.configurations_by_key_full[configuration['type'] + '.' + configuration['key']] = configuration
    },

    [SET_CURRENT_CONFIGURATION](state, { configuration }) {
        state.current_configuration = configuration
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
