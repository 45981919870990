import axios from 'axios'

const state = {
    status: 'success',
    token: localStorage.getItem('authToken') || '',
}

const getters = {
    has_token: (state) => !!state.token,
    is_logged_in: (state) => !!state.token && state.status == 'success',
}

const actions = {
    async user_profile({ getters, commit }) {
        if (!getters['has_token']) return false

        try {
            const response = await axios.get(this.$conf.apiUrl + 'users/me')

            const token = response.data.user.token

            const user = {
                id: response.data.user.id,
                username: response.data.user.username,
                email: response.data.user.email,
                roles: response.data.user.roles,
                permissions: response.data.user.permissions,
            }

            if (!response.data.user.roles.includes('superAdmin') && !response.data.user.roles.includes('admin')) {
                commit('AUTH_ERROR')
                return
            }

            axios.defaults.headers.common['Authorization'] = 'Bearer  ' + token
            localStorage.setItem('authToken', token)

            commit('AUTH_SUCCESS', { token })

            commit('users/SET_CURRENT_USER', { user }, { root: true })

            return response.data
        } catch (e) {
            commit('AUTH_ERROR')
            throw e
        }
    },

    async login({ commit }, credentials) {
        commit('AUTH_REQUEST')
        try {
            // Special case for passwords with double backslashes
            credentials['user']['password'] = credentials['user']['password'].replace(/\\\\/g, '\\')

            const response = await axios.post(this.$conf.apiUrl + 'users/login', credentials)
            const token = response.data.user.token
            const user = {
                id: response.data.user.id,
                username: response.data.user.username,
                email: response.data.user.email,
                roles: response.data.user.roles,
                permissions: response.data.user.permissions,
            }

            if (!response.data.user.roles.includes('superAdmin') && !response.data.user.roles.includes('admin')) {
                commit('AUTH_ERROR')
                return
            }

            axios.defaults.headers.common['Authorization'] = 'Bearer  ' + token
            localStorage.setItem('authToken', token)

            commit('AUTH_SUCCESS', { token })

            commit('users/SET_CURRENT_USER', { user }, { root: true })
        } catch (e) {
            commit('AUTH_ERROR')
            throw e
        }
    },
    SSOLoginRedirect(){
        window.location.href = this.$conf.apiUrl + 'sso-login?relay_url=' + encodeURIComponent(window.location.origin+'/login?user_data=%s&error=%e');
    },
    formatError({ commit }, e){
        commit('AUTH_ERROR')
        return String(e);
    },
    async automaticLogin({ commit }, string_data) {
        commit('AUTH_REQUEST')

            const data = JSON.parse(decodeURIComponent(string_data));
            try {
                const token = data.user.token
                const user = {
                    id: data.user.id,
                    username: data.user.username,
                    email: data.user.email,
                    roles: data.user.roles,
                    permissions: data.user.permissions,
                }

                if (!data.user.roles.includes('superAdmin') && !data.user.roles.includes('admin')) {
                    commit('AUTH_ERROR')
                    return
                }

                axios.defaults.headers.common['Authorization'] = 'Bearer  ' + token
                localStorage.setItem('authToken', token)

                commit('AUTH_SUCCESS', { token })

                commit('users/SET_CURRENT_USER', { user }, { root: true })
            } catch (e) {
                commit('AUTH_ERROR')
                throw e
            }
    },

    logout({ commit }) {
        commit('LOGOUT')
        localStorage.removeItem('authToken')
        delete axios.defaults.headers.common['X-Access-Token']
    },
}

const mutations = {
    AUTH_REQUEST(state) {
        state.status = 'loading'
    },

    // @param token: String
    // @param user: Object
    AUTH_SUCCESS(state, { token }) {
        state.status = 'success'
        state.token = token
    },

    AUTH_ERROR(state) {
        state.status = 'error'
    },

    LOGOUT(state) {
        state.status = ''
        state.token = ''
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
