import axios from 'axios'
import Helpers from '../../../plugins/Helpers'

const ADD_MARGINS = 'ADD_MARGINS'

const state = {
    margins: {},
    margins_full: {},
    margins_promise: Promise.resolve(),
}

const getters = {
    getMarginsTable: (state) => (supplierName) => {
        const table = {}
        const data = {}

        const margins = Object.assign([], state.margins_full[supplierName]).filter((n) => n)
        const marginBySupplier = Object.assign([], Helpers.groupBy(margins, 'supplier_id'))

        marginBySupplier.forEach((margins, marginSupplier) => {
            margins.forEach((margin) => {
                data[margin.client_id] = margin.margin
            })
            table[marginSupplier] = data
        })
        return Object.assign([], table)
    },
}

const actions = {
    async get_supplier_margins(context, { supplierName }) {
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/b2c/margins/supplier/' + supplierName)
            .then((response) => {
                return response.data.suppliers_b2c_margins
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async update(context, { supplierName, data }) {
        return await axios
            .put(this.$conf.apiUrl + 'suppliers/b2c/margins/' + supplierName, {
                margins: data,
            })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    let message = ''
                    error.response.data.errors.forEach((error) => {
                        message = message + error + '<br>'
                    })
                    context.dispatch(
                        'alerts/displayAlert',
                        { type: 'error', title: 'API Error', message: this.$sanitize(message), autoclose: true },
                        { root: true }
                    )
                    //console.log(error.response.data);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request)
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message)
                }
                //console.log(error.config);
                return false
            })
    },

    async load_margins({ dispatch, commit }, { supplierName }) {
        return await dispatch('get_supplier_margins', { supplierName: supplierName }).then(async (items) => {
            const promise = await dispatch('NORMALIZE_DATA', { items: items, refKey: 'id', supplierName })
            commit('LOAD_PROMISE', promise)
            return promise
        })
    },

    async update_margins({ dispatch, commit, rootState }, { supplierName, data }) {
        return await dispatch('update', { supplierName: supplierName, data: data }).then(async (data) => {
            if (data) {
                const supplierName = rootState.suppliers.active_supplier.name
                commit(ADD_MARGINS, { margins: [...data.suppliers_b2c_margins], supplierName })
                return data
            }
            return false
        })
    },

    NORMALIZE_DATA({ commit }, { items, refKey, supplierName }) {
        // Set an Array of ids
        const list = Object.freeze(items.map((o) => parseInt(o[refKey])))
        // Set an Object of Objects with full data, freezed for no reactivity
        const data = Object.freeze(this.$Helpers.array2Object(items, refKey))
        // Insert data to store
        commit('ADD_DATA', { list, data, supplierName })
    },
}

const mutations = {
    // @param type: String
    // @param list: Array
    // @param data: Object
    ADD_DATA(state, { list, data, supplierName }) {
        if (!Object.prototype.hasOwnProperty.call(state['margins'], supplierName)) {
            state['margins'][supplierName] = []
            state['margins_full'][supplierName] = {}
        }

        // Add entry if not existing
        for (const id of list) {
            if (!state['margins'][supplierName].includes(id)) {
                state['margins_full'][supplierName][id] = data[id]
                state['margins'][supplierName].push(id)
            }
        }
    },

    [ADD_MARGINS](state, { margins, supplierName }) {
        for (const margin of margins) {
            if (!state['margins'][supplierName].includes(margin.id)) {
                state['margins'][supplierName].push(margin.id)
            }
            state['margins_full'][supplierName][margin.id] = margin
        }
    },

    // @param promise: Promise
    LOAD_PROMISE(state, promise) {
        state['margins_promise'] = promise
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
