import axios from 'axios'

const actions = {
    async list() {
        return await axios
            .get(this.$conf.apiUrl + 'countries')
            .then((response) => {
                return response.data.countries
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },
}

export default {
    namespaced: true,
    actions,
}
