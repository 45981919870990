const state = {
    alerts: [],
}

const getters = {}

const actions = {
    async displayAlert({ dispatch, commit }, payload) {
        commit('displayAlert', payload)
        if (payload.autoclose) {
            const autocloseDelay = payload.autocloseDelay ?? 4000
            await new Promise((resolve) => setTimeout(() => resolve(), autocloseDelay))
            dispatch('removeAlert')
        }
    },

    removeAlert({ commit }) {
        commit('removeAlert')
    },
}

const mutations = {
    displayAlert(state, payload) {
        state.alerts = [...state.alerts, { ...payload, id: Symbol() }]
    },
    removeAlert(state) {
        const alerts = [...state.alerts]
        alerts.shift()
        state.alerts = [...alerts]
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
