import axios from 'axios'

const state = {
    ean: '',
    products: [],
    product: {},
}

const getters = {}

const actions = {
    async searchByEan(context, { ean }) {
        return await axios
            .get(this.$conf.apiUrl + 'products?ean=' + ean)
            .then((response) => {
                return response.data.products
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async searchWith(context, { sku, ean, asin, name }) {
        let queryString = '?'

        if(sku){
            queryString = queryString + '&sku=' + sku
        }

        if (ean) {
            queryString = queryString + '&ean=' + ean
        }

        if (asin) {
            queryString = queryString + '&asin=' + asin
        }

        if (name) {
            queryString = queryString + '&name=' + name
        }

        return await axios
            .get(this.$conf.apiUrl + 'products' + queryString)
            .then((response) => {
                return response.data.products
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async searchUserProductWith(context, { sku, ean, asin, userId }) {
        let queryString = '?'

        if(sku){
            queryString = queryString + '&internalSku=' + sku
        }

        if (ean) {
            queryString = queryString + '&ean13=' + ean
        }

        if (asin) {
            queryString = queryString + '&asin=' + asin
        }

        if (userId) {
            queryString = queryString + '&user_id=' + userId
        }

        return await axios
            .get(this.$conf.apiUrl + 'user-products' + queryString)
            .then((response) => {
                return response.data.user_products
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async getProduct(context, { id }) {
        return await axios
            .get(this.$conf.apiUrl + 'products/' + id)
            .then((response) => {
                return response.data.product
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async saveProduct(context, { id, data }) {
        return await axios
            .post(this.$conf.apiUrl + 'products/' + id, data)
            .then((response) => {
                return response.data.product
            })
            .catch((e) => {
                console.log(e, 'Unable to save product')
            })
    },
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
