import axios from 'axios'

const state = {
    productDetails: {},
}

const getters = {}

const actions = {
    async saveProductDetails(context, { id, data }) {
        return await axios
            .post(this.$conf.apiUrl + 'product-details/' + id, data)
            .then((response) => {
                return response.data.productDetails
            })
            .catch((e) => {
                console.log(e, 'Unable to save product details')
            })
    },

    async batchSaveProductDetails(context, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'product-details/import/batch', data)
            .then((response) => {
                return response.data
            })
            .catch((e) => {
                console.log(e, 'Unable to batch save product details')
            })
    },

    async uploadExcelFile(context, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'product-details/import/excel', data)
            .then((response) => {
                return response.data
            })
            .catch((e) => {
                throw new Error(e.response.data.errors.message ?? 'Error!')
            })
    },
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
