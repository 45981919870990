// Async component loading
const NotFound = () => import('../views/NotFound')
const Backoffice = () => import('../views/Backoffice')
const Login = () => import('../views/Login')
const PassThrough = () => import('../views/PassThrough')

// General
const Dashboard = () => import('@/modules/General/Dashboard/Dashboard')
const UsersManagement = () => import('@/modules/General/UsersManagement/UsersManagement')
const GlobalCatalogExport = () => import('@/modules/Catalog/GlobalCatalogExport/GlobalCatalogExport')

// Settings
const PaymentTerms = () => import('@/modules/Settings/PaymentTerms/PaymentTerms')
const ShippingWeights = () => import('@/modules/Settings/ShippingWeights/ShippingWeights')
const ConfigurationManagement = () => import('@/modules/Settings/Configurations/ConfigurationManagement')
const JobBatches = () => import('@/modules/Settings/Batches/JobBatches')

// Catalog
const Products = () => import('@/modules/Catalog/Products/Products')
const UserProductsConditions = () => import('@/modules/Catalog/UserProductsConditions/UserProductsConditions')

// Supplier
const CreateSupplier = () => import('@/modules/Supplier/CreateSupplier/CreateSupplier')
const SupplierB2C = () => import('@/modules/Supplier/B2C/SupplierB2C')
const SupplierGeneral = () => import('@/modules/Supplier/General/SupplierGeneral')
const SupplierInventory = () => import('@/modules/Supplier/Inventory/SupplierInventory')

// Operations
const SuppliersOverview = () => import('@/modules/Operations/SuppliersOverview/SuppliersOverview')
const FeedsSynchro = () => import('@/modules/Operations/FeedsSynchronization/FeedsSynchronization')

// Accounting
const AccountingInvoices = () => import('@/modules/Accounting/Invoices/Invoices')
const AccountingPcn = () => import('@/modules/Accounting/pcn/PcnPage')

// B2B SupplierPriceGenerator
const B2BOverviewPrPo = () => import('@/modules/B2B/Overview/Overview')
const B2BBolComOrders = () => import('@/modules/B2B/BolCom/Orders')
const B2BInboundsOverview = () => import('@/modules/B2B/Inbounds/InboundsOverview')
const B2BInboundsPreparationStatusUnitsWithProblems = () => import('@/modules/B2B/Inbounds/PreparationStatusUnitsWithProblems')
const B2BOutboundsOverview = () => import('@/modules/B2B/Outbounds/Outbounds')
const B2BCommercialInvoicesOverview = () => import('@/modules/B2B/CommercialInvoices/CommercialInvoicesOverview')
const B2BCommercialInvoicesDetails = () => import('@/modules/B2B/CommercialInvoices/CommercialInvoicesDetails')
const B2BShipments = () => import('@/modules/B2B/Shipments/Shipments')
const PRControlBoard = () => import('@/modules/B2B/PRControlBoard/PRControlBoard')
const SupplierPriceGenerator = () => import('@/modules/B2B/SupplierPriceGenerator/SupplierPriceGenerator')

// B2C
const B2COrders = () => import('@/modules/B2C/Orders/Orders')
const B2COrderDetails = () => import('@/modules/B2C/Orders/OrderDetails')
const B2CShipments = () => import('@/modules/B2C/Shipments/Shipments')
const B2CExcludedSkus = () => import('@/modules/B2C/ExcludedSkus/ExcludedSkus')
const B2CWhitelists = () => import('@/modules/B2C/Whitelists/Whitelists')
const B2CWhitelistsRecommendations = () => import('@/modules/B2C/Whitelists/Recommendations/Recommendations')
const B2CFBA = () => import('@/modules/B2C/FBA/FBA')

const ShipmentLocations = () => import('@/modules/B2C/ShipmentLocations/ShipmentLocations')
const ShipmentLocationForm = () => import('@/modules/B2C/ShipmentLocations/ShipmentLocationForm')

const DiscountCampaign = () => import('@/modules/B2C/DiscountCampaign/DiscountCampaign')
const DiscountCampaignForm = () => import('@/modules/B2C/DiscountCampaign/DiscountCampaignForm')

const ExcludedList = () => import('@/modules/B2C/ExcludedList/ExcludedList')

const ClientsOverview = () => import('@/modules/Client/ClientsOverview.vue')

export default [
    // Login
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Lulubuy Login',
        },
    },
    // Backoffice
    {
        path: '/',
        name: '',
        component: Backoffice,
        meta: {
            title: 'Lulubuy BackOffice',
        },
        props: (route) => ({ supplier: route.query.supplier }),
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: {
                    title: 'Dashboard',
                    breadcrumb: 'Dashboard',
                },
            },
            {

                path: 'it',
                redirect: { path: '/dashboard' },
                name: 'IT',
                component: PassThrough,
                meta: {
                    title: 'it',
                    breadcrumb: 'IT',
                },
                children: [
                    {
                        path: 'payment-terms',
                        name: 'Payment Terms',
                        component: PaymentTerms,
                        meta: {
                            title: 'Payment Terms',
                            breadcrumb: 'Payment Terms',
                        },
                    },
                    {
                        path: 'shipping-weights',
                        name: 'Shipping Weights',
                        component: ShippingWeights,
                        meta: {
                            title: 'Shipping Weights',
                            breadcrumb: 'Shipping Weights',
                        },
                    },
                    {
                        path: 'excluded-skus',
                        name: 'Excluded Skus',
                        component: B2CExcludedSkus,
                        meta: {
                            title: 'B2C Excluded Skus',
                            breadcrumb: 'Excluded Skus',
                        },
                    },
                    {
                        path: 'configurations',
                        name: 'Configurations',
                        component: ConfigurationManagement,
                        meta: {
                            title: 'Configurations',
                            breadcrumb: 'Configurations',
                        },
                    },
                ],
            },
            {
                path: 'settings',
                redirect: { path: '/dashboard' },
                name: 'Settings',
                component: PassThrough,
                meta: {
                    title: 'Settings',
                    breadcrumb: 'Settings',
                },
                children: [
                    {
                        path: 'users',
                        name: 'Users',
                        component: UsersManagement,
                        meta: {
                            title: 'Users',
                            breadcrumb: 'User management',
                        },
                    },
                    {
                        path: 'jobBatches',
                        name: 'Job Batches',
                        component: JobBatches,
                        meta: {
                            title: 'Job Batches',
                            breadcrumb: 'Job Batches',
                        },
                    },
                ],
            },
            {
                path: 'catalog',
                redirect: { path: '/dashboard' },
                name: 'Catalog',
                component: PassThrough,
                meta: {
                    title: 'Catalog',
                    breadcrumb: 'Catalog',
                },
                children: [
                    {
                        path: 'customs-database',
                        name: 'Products',
                        component: Products,
                        meta: {
                            title: 'Products',
                            breadcrumb: 'Customs Data Base',
                        },
                    },
                    {
                        path: 'user-products-conditions',
                        name: 'User Products Conditions',
                        redirect: { path: '/dashboard' },
                        component: UserProductsConditions,
                        meta: {
                            title: 'User Products Conditions',
                            breadcrumb: 'User Products Conditions',
                        },
                    },
                    {
                        path: 'discount-campaign',
                        component: PassThrough,
                        meta: {
                            title: 'Supplier Discount Campaign',
                            breadcrumb: 'Discount Campaign',
                        },
                        children: [
                            {
                                path: '',
                                name: 'discount-campaign',
                                component: DiscountCampaign,
                                meta: {
                                    title: 'Supplier Discount Campaign',
                                },
                            },
                            {
                                path: 'create',
                                name: 'create-discount-campaign',
                                component: DiscountCampaignForm,
                                meta: {
                                    title: 'Supplier Create Discount Campaign',
                                    breadcrumb: 'Create Discount Campaign',
                                },
                            },
                            {
                                path: 'update/:id',
                                name: 'update-discount-campaign',
                                component: DiscountCampaignForm,
                                meta: {
                                    title: 'Supplier Update Discount Campaign',
                                    breadcrumb: 'Update Discount Campaign',
                                },
                            },
                        ],
                    },
                    {
                        path: 'global',
                        name: 'Global Catalog',
                        component: GlobalCatalogExport,
                        meta: {
                            title: 'Global Catalog',
                            breadcrumb: 'Global Catalog',
                        },
                    },
                ],
            },
            {
                path: 'third-party-manager',
                redirect: { path: '/dashboard' },
                name: 'Operations',
                component: PassThrough,
                meta: {
                    title: 'Operations',
                    breadcrumb: 'Third party manager',
                },
                children: [
                    {
                        path: 'suppliers',
                        redirect: { path: '/dashboard' },
                        name: 'Suppliers',
                        component: PassThrough,
                        meta: {
                            title: 'Suppliers Overview',
                            breadcrumb: 'Suppliers Overview',
                        },
                        children: [
                            {
                                path: 'overview',
                                name: '',
                                component: PassThrough,
                                children: [
                                    {
                                        path: '',
                                        name: 'Suppliers Overview',
                                        component: SuppliersOverview,
                                    },
                                    {
                                        path: 'general',
                                        name: 'Operations Supplier General',
                                        meta: {
                                            breadcrumb: queryParams => queryParams.get('supplier'),
                                        },
                                        children: [
                                            {
                                                path: '',
                                                name: 'Supplier General',
                                                component: SupplierGeneral,
                                                meta: {
                                                    title: 'Supplier General',
                                                },
                                                props: (route) => ({ supplier: route.query.supplier }),
                                            },
                                            {
                                                path: 'inventory',
                                                name: 'Inventory',
                                                component: SupplierInventory,
                                                meta: {
                                                    title: 'Supplier Inventory',
                                                    breadcrumb: 'Feed upload',
                                                },
                                                props: (route) => ({ supplier: route.query.supplier }),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: 'create',
                                name: 'Create Supplier',
                                component: CreateSupplier,
                                meta: {
                                    title: 'Create new supplier',
                                    breadcrumb: 'Create new supplier',
                                },
                            },
                        ],
                    },
                    {
                        path: 'clients',
                        redirect: { path: '/dashboard' },
                        name: 'Clients',
                        component: PassThrough,
                        meta: {
                            title: 'Clients Overview',
                            breadcrumb: 'Clients Overview',
                        },
                        children: [
                            {
                                path: 'overview',
                                name: '',
                                component: PassThrough,
                                children: [
                                    {
                                        path: '',
                                        name: 'Client Overview',
                                        component: ClientsOverview,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'feeds',
                        name: 'Feeds Synchronization',
                        component: FeedsSynchro,
                        meta: {
                            title: 'Operations - Feeds Synchronization',
                            breadcrumb: 'Feeds Synchronization',
                        },
                    },
                ],
            },
            {
                path: 'accounting',
                redirect: { path: '/dashboard' },
                name: 'Accounting',
                component: PassThrough,
                meta: {
                    title: 'Accounting',
                    breadcrumb: 'Accounting',
                },
                children: [
                    {
                        path: 'invoices',
                        name: 'Invoices',
                        component: AccountingInvoices,
                        meta: {
                            title: 'Accounting - Invoices',
                            breadcrumb: 'Invoices',
                        },
                    },
                    {
                        path: 'pcn',
                        name: 'pcn',
                        component: AccountingPcn,
                        meta: {
                            title: 'Accounting - Pcn',
                            breadcrumb: 'Pcn',
                        },
                    },
                ],
            },
            {
                path: 'b2b',
                redirect: { path: '/dashboard' },
                name: 'B2B',
                meta: {
                    title: 'B2B',
                    breadcrumb: 'B2B',
                },
                component: PassThrough,
                children: [
                    {
                        path: 'orders',
                        name: 'Overview PR/PO',
                        component: B2BOverviewPrPo,
                        meta: {
                            title: 'B2B Overview PR/PO',
                            breadcrumb: 'Orders',
                        },
                    },
                    {
                        path: 'bol-com/orders',
                        name: 'Bol.com Orders',
                        component: B2BBolComOrders,
                        meta: {
                            title: 'Bol.com Orders',
                            breadcrumb: 'Bol.com Orders',
                        },
                    },
                    {
                        path: 'inbounds',
                        name: 'Inbounds',
                        component: B2BInboundsOverview,
                        meta: {
                            title: 'B2B Inbounds Overview',
                            breadcrumb: 'Inbounds',
                        },
                    },
                    {
                        path: 'inbounds/preparation-status/:poId/problems',
                        name: 'preparation-status-units-with-problems',
                        component: B2BInboundsPreparationStatusUnitsWithProblems,
                        meta: {
                            title: 'B2B Inbounds Preparation Status Units With Problems',
                            breadcrumb: 'Preparation Status',
                            additionalBreadcrumbs: [
                                {
                                    label: 'Units with problems',
                                }
                            ],
                        },
                    },
                    {
                        path: 'outbounds',
                        name: 'outbounds',
                        component: B2BOutboundsOverview,
                        meta: {
                            title: 'B2B Outbounds',
                            breadcrumb: 'Outbounds',
                        },
                    },
                    {
                        path: 'commercial-invoices',
                        name: 'commercial-invoices',
                        component: B2BCommercialInvoicesOverview,
                        meta: {
                            title: 'B2B Commercial Invoices',
                            breadcrumb: 'Commercial Invoices',
                        },
                    },
                    {
                        path: 'commercial-invoices/:id',
                        name: 'commercial-invoice-details',
                        component: B2BCommercialInvoicesDetails,
                        meta: {
                            title: 'B2B Commercial Invoices',
                            breadcrumb: 'Commercial Invoices',
                        },
                    },
                    {
                        path: 'shipments',
                        name: 'shipments',
                        component: B2BShipments,
                        meta: {
                            title: 'Shipments Data',
                            breadcrumb: 'Shipments',
                        },
                    },
                    {
                        path: 'supplier-price-generator',
                        name: 'supplier-price-generator',
                        component: SupplierPriceGenerator,
                        meta: {
                            title: 'Supplier Price Generator',
                            breadcrumb: 'SP Generator',
                        },
                    },
                    /*{
                     path: 'outbounds',
                     name: 'Outbounds',
                     component: B2BOutbounds,
                     meta: {
                         title: 'B2B Outbounds'
                     }
                 },*/
                    /*{
                    path: 'invoices',
                    name: 'Invoices',
                    component: Invoices,
                    meta: {
                        title: 'B2B Invoices'
                    }
                },*/
                    {
                        path: 'pr-control-board',
                        name: 'pr-control-board',
                        component: PRControlBoard,
                        meta: {
                            title: 'B2B PRControl Board',
                            breadcrumb: 'PR Control Board',
                        },
                    },
                    {
                        path: 'shipment-location',
                        component: PassThrough,
                        meta: {
                            title: 'Shipment Location',
                            breadcrumb: 'Shipment Location',
                        },
                        children: [
                            {
                                path: '',
                                name: 'shipment-location',
                                component: ShipmentLocations,
                                meta: {
                                    title: 'Shipment Location',
                                },
                            },
                            {
                                path: 'create',
                                name: 'create-shipment-location',
                                component: ShipmentLocationForm,
                                meta: {
                                    title: 'Shipment Location',
                                    breadcrumb: 'Create Shipment Location',
                                },
                            },
                            {
                                path: 'update/:id',
                                name: 'update-shipment-location',
                                component: ShipmentLocationForm,
                                meta: {
                                    title: 'Update Shipment Location',
                                    breadcrumb: 'Update Shipment Location',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: 'b2c',
                redirect: { path: '/dashboard' },
                name: 'B2C',
                component: PassThrough,
                meta: {
                    title: 'B2C',
                    breadcrumb: 'B2C',
                },
                children: [
                    {
                        path: 'orders',
                        name: 'Orders',
                        component: B2COrders,
                        meta: {
                            title: 'B2C Orders',
                            breadcrumb: 'Orders',
                        },
                    },
                    {
                        path: 'orders/:orderId/:sellerSku',
                        name: 'Order Details',
                        component: B2COrderDetails,
                        meta: {
                            title: 'B2C Order Details',
                            breadcrumb: 'Order Details',
                        },
                    },
                    {
                        path: 'shipments',
                        name: 'Shipments',
                        component: B2CShipments,
                        meta: {
                            title: 'B2C Shipments',
                            breadcrumb: 'Shipments',
                        },
                    },
                    {
                        path: 'fba',
                        name: 'Amazon FBA',
                        component: B2CFBA,
                        meta: {
                            title: 'B2C Amazon FBA',
                            breadcrumb: 'FBA',
                        },
                    },
                    {
                        path: 'dropshipper-details',
                        name: 'Supplier B2C',
                        component: SupplierB2C,
                        meta: {
                            title: 'Supplier B2C',
                            breadcrumb: 'Dropshipper Details',
                            additionalBreadcrumbs: [
                                {
                                    label: queryParams => queryParams.get('supplier'),
                                }
                            ],
                        },
                        props: (route) => ({ supplier: route.query.supplier }),
                    },
                    {
                        path: 'excluded-list',
                        name: 'excluded-list',
                        component: ExcludedList,
                        meta: {
                            title: 'Excluded List',
                            breadcrumb: 'Excluded List',
                        },
                    },
                    {
                        path: 'whitelists',
                        name: 'Clients Whitelists',
                        component: B2CWhitelists,
                        meta: {
                            title: 'B2C Clients Whitelists',
                            breadcrumb: 'Whitelists',
                        },
                    },
                    {
                        path: 'whitelists/recommendations',
                        name: 'Recommended products to add',
                        component: B2CWhitelistsRecommendations,
                        meta: {
                            title: 'Recommended products to add',
                            breadcrumb: 'Whitelists - Recommended products to add',
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: 'Page not found',
            breadcrumb: 'Page not found',
        },
    },
]
