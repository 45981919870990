import util from '../util/index'

/**
 *
 * 12345 => $12,345.00
 *
 * @param {String} symbol
 * @param {Number} decimals Decimal places
 * @param {Object} options
 */

const globalOptions = {
    symbol: '€',
    decimalDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: false,
    spaceBetweenAmountAndSymbol: true,
}

function currency(value, symbol, decimals, options) {
    symbol = util.exist(symbol) ? symbol : globalOptions.symbol
    decimals = util.exist(decimals) ? decimals : globalOptions.decimalDigits
    options = options || globalOptions
    const digitsRE = /(\d{3})(?=\d)/g
    value = parseFloat(value)
    if (!isFinite(value) || (!value && value !== 0)) return ''
    symbol = typeof symbol !== 'undefined' ? symbol : '$'
    decimals = typeof decimals !== 'undefined' ? decimals : 2
    const thousandsSeparator = options.thousandsSeparator != null ? options.thousandsSeparator : ','
    const symbolOnLeft = options.symbolOnLeft != null ? options.symbolOnLeft : true
    const spaceBetweenAmountAndSymbol = options.spaceBetweenAmountAndSymbol != null ? options.spaceBetweenAmountAndSymbol : false
    const showPlusSign = options.showPlusSign != null ? options.showPlusSign : false
    const number = Math.abs(value)
    let stringified = toFixed(number, decimals)
    stringified = options.decimalSeparator ? stringified.replace('.', options.decimalSeparator) : stringified
    const _int = decimals ? stringified.slice(0, -1 - decimals) : stringified
    const i = _int.length % 3
    const head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? thousandsSeparator : '') : ''
    const _float = decimals ? stringified.slice(-1 - decimals) : ''
    symbol = spaceBetweenAmountAndSymbol ? (symbolOnLeft ? symbol + ' ' : ' ' + symbol) : symbol
    symbol = symbolOnLeft
        ? symbol + head + _int.slice(i).replace(digitsRE, '$1' + thousandsSeparator) + _float
        : head + _int.slice(i).replace(digitsRE, '$1' + thousandsSeparator) + _float + symbol
    const sign = value < 0 ? '-' : ''
    const plusSign = value > 0 && showPlusSign ? '+' : ''
    return plusSign + sign + symbol
}

function toFixed(num, precision) {
    return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision)
}

export default currency
