import axios from 'axios'

const state = {
    payment_terms: [],
    payment_terms_full: {},
}

const getters = {
    // @param type:String
    // @param id: Number|String
    obj_list_with_parent_id:
        (state, rootState) =>
        ({ type, id }) => {
            const parentIdKey = rootState.types[type].parentIdKey
            return state[type].filter((childrenId) => state[type + '_full'][childrenId][parentIdKey] == id)
        },

    label_id_name: (state) => (o) => {
        return o.id + ' - ' + state[o.type + '_full'][o.id].name
    },

    label_id_name_html: (state) => (o) => {
        return '<span>' + o.id + '</span>&nbsp;-&nbsp;' + state[o.type + '_full'][o.id].name
    },

    label_type_id_name: (state) => (o) => {
        return o.type.toUpperCase() + ' ' + o.id + ' - ' + state[o.type + '_full'][o.id].name
    },

    label_type_id_name_lowercase: (getters) => (o) => {
        return getters['label_type_id_name'](o).toLowerCase()
    },

    parent_id: (state, rootState) => (o) => {
        const parentIdKey = rootState.types[o.type].parentIdKey
        return parseInt(state[o.type + '_full'][o.id][parentIdKey])
    },
}

const actions = {
    // API Call
    // @param entityName: String
    // @param requestType: Array
    async fetch(context, { endpoint, queryString = '' }) {
        try {
            const response = await axios.get(this.$conf.apiUrl + endpoint + queryString)
            const items = response.data[endpoint.replace(/-/g, '_')]
            return items
        } catch (e) {
            console.error('ERROR FETCHING DV OBJECTS', e)
        }
    },

    async fetch_source({ dispatch, rootState }, { type }) {
        const refKey = rootState.types[type].idKey
        const endpoint = rootState.types[type].endpoint
        const { items, cursor } = await dispatch('fetch', { endpoint })
        dispatch('NORMALIZE_DATA', { items, type, refKey }).then(() => {
            if (cursor) dispatch('fetch_source', { type, startCursor: cursor })
        })
    },

    async fetch_payment_terms({ dispatch }) {
        const endpoint = 'payment-terms'
        const type = 'payment_terms'
        const refKey = 'id'
        const items = await dispatch('fetch', { endpoint })
        dispatch('NORMALIZE_DATA', { items, type, refKey })
    },

    NORMALIZE_DATA({ commit }, { items, type, refKey }) {
        // Set an Array of ids
        const list = Object.freeze(items.map((o) => parseInt(o[refKey])))
        // Set an Object of Objects with full fata, freezed for no reactivity
        const data = Object.freeze(this.$Helpers.array2Object(items, refKey))
        // Insert data to store
        commit('ADD_DATA', { type, list, data })
    },
}

const mutations = {
    // @param type: String
    // @param list: Array
    // @param data: Object
    ADD_DATA(state, { type, list, data }) {
        // Add entry if not existing
        for (const id of list) {
            if (!state[type].includes(id)) {
                state[type + '_full'][id] = data[id]
                state[type].push(id)
            }
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
