import axios from "axios";

const state = {
    accounts: [],
    accounts_full:{}
}

const getters = {

}

const actions = {
    async getAccounts(context, { pcursor, label, perPage}){
        let queryString = '?'

        if (pcursor) {
            queryString = queryString + '&page=' + pcursor
        }

        if (label) {
            queryString = queryString + '&label=' + label
        }
        if (perPage) {
            queryString = queryString + '&perPage=' + perPage
        }
        return await axios.get(this.$conf.apiUrl + 'account' + queryString)
            .then((res)=>{
                return res.data
            })
            .catch((e)=>{
                throw e
            })
    },

    async getOneAccount(context, { id }) {
        return await axios
            .get(this.$conf.apiUrl + 'account/' + id)
            .then((res) => {
                return res.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async addAccount(context, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'account', data)
            .then((response) => {
                return response.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async deleteAccount(context, data){
        await axios
            .delete(this.$conf.apiUrl + 'account/' + data)
            .then((res)=>{
                return res
            })
            .catch((e)=>{
                const deleteErrorMessage = JSON.parse(e.request.responseText).errors.message
                throw deleteErrorMessage
            })

    },

    async updateAccount({ commit }, { id, data }) {
        return await axios
            .put(this.$conf.apiUrl + 'account/' + id, data)
            .then((res) => {
                return res.data
            })
            .catch((e) => {
                console.log(e, 'Unable to save account')
            })
    },

    async isFav(context, {data}){
        let account = {
            "accounting_code": {
                "favorite": data.accounting_code.favorite,
            }
        }
        let id = data.id
        await axios.put(this.$conf.apiUrl + 'account/'+id, account)
            .then((res)=>{
                return res
            })
            .catch((e)=>{
                throw e
            })
    }
}

const mutations = {

    REPLACE_DATA(state, { list, data }) {
        state['accounts_full'] = {}
        state['accounts'] = []

        for (const id of list) {
            if (!state['accounts'].includes(id)) {
                state['accounts_full'][id] = data[id]
                state['accounts'].push(id)
            }
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

