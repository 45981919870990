import axios from 'axios'

const state = {}

const getters = {}

const actions = {
    async getExcludedSkus(context, { pcursor, seller_sku, perPage}){
        let queryString = '?'

        if (pcursor) {
            queryString = queryString + '&page=' + pcursor
        }

        if (seller_sku) {
            queryString = queryString + '&seller_sku=' + seller_sku
        }
        if (perPage) {
            queryString = queryString + '&perPage=' + perPage
        }
       return await axios.get(this.$conf.apiUrl + 'excluded-skus'+ queryString)
           .then((res)=>{
               return res.data
           })
           .catch((e)=>{
               return e
           })
    },

    async deleteExcludedSku(context, data) {
        return await axios.delete(this.$conf.apiUrl + 'excluded-skus/' + data.id)
            .then((res) =>{
                return res.data
            })
            .catch((e) => {
                return e
            })
    }
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
