import axios from 'axios'

const UPDATE_META = 'UPDATE_META'

const state = {
    campaigns_full: {},
    campaigns_meta: {},
}

const getters = {}

const actions = {

    async fetchCampaigns(context, { queryString }) {
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/supplier-discount-campaigns' + queryString)
            .then((response) => {
                const { supplier_discount_campaigns: campaigns, ...meta } = response.data;
                return { campaigns, meta }
            })
    },

    async load_campaigns({ dispatch }, { perPage, supplier, pcursor }) {
        let queryString = '?'

        if (supplier) {
            queryString = queryString + '&supplier_id=' + supplier
        }

        if (perPage) {
            queryString = queryString + '&perPage=' + perPage
        }

        if (pcursor) {
            queryString = queryString + '&page=' + pcursor
        }

        return await dispatch('fetchCampaigns', { queryString }).then(async (data) => {
            return data;
        })
    },

    async postCampaign(context, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'suppliers/supplier-discount-campaigns', data)
            .then((response) => {
                return response.data.supplier_discount_campaign
            })
    },

    async getCampaign(context, { id }) {
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/supplier-discount-campaigns/' + id)
            .then((response) => {
                return response.data.data
            })
    },

    async updateCampaign(_, { id, data }) {
        return await axios
            .put(this.$conf.apiUrl + 'suppliers/supplier-discount-campaigns/' + id, data)
            .then((response) => {
                return response.data.supplier_discount_campaign
            })
    },

    async attachProduct(_, { id, data }) {
        return await axios
            .post(this.$conf.apiUrl + 'suppliers/supplier-discount-campaigns/' + id + '/products/' , data)
            .then((response) => {
                return response.data
            })
    },

    async removeProduct(_, { id, itemId }) {
        return await axios
            .delete(this.$conf.apiUrl + 'suppliers/supplier-discount-campaigns/' + id + '/products/' + itemId)
            .then((response) => {
                return response.data
            })
    },

    async upload_csv(_, { id, data }) {
        return await axios
            .post(this.$conf.apiUrl + 'suppliers/supplier-discount-campaigns/upload_csv/' + id, data)
            .then((response) => {
                return response.data
            })
    },

    async upload_campaigns(_, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'suppliers/supplier-discount-campaigns/import_campaign', data)
            .then((response) => {
                return response.data
            })
    },

    async deleteCampaign(_, { id }) {
        return await axios
            .delete(this.$conf.apiUrl + 'suppliers/supplier-discount-campaigns/' + id)
            .then((response) => {
                return response.data
            })
    },

}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
