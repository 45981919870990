import axios from 'axios'
import Helpers from "@/plugins/Helpers";

const UPDATE_META = 'UPDATE_META'

const state = {
    shipments_full: {},
    shipments_meta: {},
}

const getters = {}

const actions = {

    async fetchShipments(context, { queryString }) {
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/b2c/shipments' + queryString)
            .then((response) => {
                if (response.data.meta) context.commit(UPDATE_META, { data: response.data.meta })
                return response.data.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async load_shipments({ dispatch }, { daterange, searchBy, searchValue, pcursor, perPage }) {
        let queryString = '?'

        if (daterange) {
            queryString = queryString + 'created_at=' + JSON.stringify(daterange)
        }

        if (searchBy) {
            queryString = queryString + '&' + searchBy + '=' + searchValue
        }

        if (pcursor) {
            queryString = queryString + '&page=' + pcursor
        }

        if (perPage) {
            queryString = queryString + '&perPage=' + perPage
        }

        return await dispatch('fetchShipments', { queryString }).then(async (items) => {
            return await dispatch('NORMALIZE_DATA', { items: items, refKey: 'id' })
        })
    },

    async getOneShipment(context, { id }) {
        return await axios
            .get(this.$conf.apiUrl + 'suppliers/b2c/shipments/'+ id)
            .then((response) => {
                return response.data.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async postShipment(context, { data }) {
        return await axios
            .post(this.$conf.apiUrl + 'suppliers/b2c/shipments', data)
            .then((response) => {
                return response.data
            })
            .catch((e) => {
                console.log(e, 'Unable to obtain data')
            })
    },

    async updateShipment({ commit }, { id, data }) {
        return await axios
            .put(this.$conf.apiUrl + 'suppliers/b2c/shipments/'+ id, data)
            .then((response) => {
                return response.data
            })
            .catch((e) => {
                console.log(e, 'Unable to save shipment')
            })
    },

    NORMALIZE_DATA({ commit }, { items, refKey }) {
        // Set an Array of ids
        const list = Object.freeze(items.map((o) => parseInt(o[refKey])))
        // Set an Object of Objects with full data, freezed for no reactivity
        const data = Object.freeze(this.$Helpers.array2Object(items, refKey))
        // Insert data to store
        commit('REPLACE_DATA', { list, data })
    },
}

const mutations = {

    REPLACE_DATA(state, { list, data }) {
        state['shipments_full'] = {}
        state['shipments'] = []

        for (const id of list) {
            if (!state['shipments'].includes(id)) {
                state['shipments_full'][id] = data[id]
                state['shipments'].push(id)
            }
        }
    },

    [UPDATE_META](state, { data }) {
        state['shipments_meta'] = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
